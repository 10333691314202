import { NotFound } from "components/Error/NotFound";
import { BadRequest } from "components/Error/BadRequest";
import { ServerError } from "components/Error/ServerError";
import { Other } from "components/Error/Other"; // 선택

interface ErrorFallbackProps {
  error: any;
}

export const ErrorFallback = ({ error }: ErrorFallbackProps) => {
  const status = error?.response?.status;
  switch (status) {
    case 400:
      return <BadRequest />;
    case 404:
      return <NotFound />;
    case 500:
      return <ServerError />;
    default:
      return <Other />; // 예: 네트워크 에러 등 기타
  }
};
