import { Flex, Text, Image, Button } from "@mantine/core";
import Error500 from "assets/error500.png";

export const ServerError = () => {
  return (
    <Flex
      direction="column"
      w="100%"
      h="100%"
      align="center"
      gap="10.125rem"
      style={{ flexShrink: 0 }}
    >
      <Flex w="100%" h="3.5rem" p="md" justify="flex-end" gap="xs">
        {/* <IconX size={24} /> */}
      </Flex>
      <Flex direction="column" h="21.6875rem" style={{ flexShrink: 0 }}>
        <Flex
          h="100%"
          justify="center"
          align="center"
          px="3.25rem"
          py="0.5625rem"
        >
          <Image src={Error500} alt="error500" />
        </Flex>
        <Flex direction="column" h="8.6875rem" justify="center" align="center">
          <Flex
            h="2.75rem"
            px="md"
            py="sm"
            ta="center"
            bg="#F8F9FA"
            justify="center"
            align="center"
            style={{ flexShrink: 0, borderRadius: "0.75rem" }}
          >
            <Text fz="1.375rem" fw={700} ta="center" color="#868E96">
              서비스 일시 중단
            </Text>
          </Flex>
          <Flex
            direction="column"
            h="5.9375rem"
            justify="center"
            align="center"
          >
            <Text fz="0.875rem" fw={400} ta="center" color="#868E96">
              현재 서비스가 원활하지 않습니다.
            </Text>
            <Text fz="0.875rem" fw={400} ta="center" color="#868E96">
              빠르게 조치하여 곧 정상화하겠습니다.
            </Text>
            <Text fz="0.875rem" fw={400} ta="center" color="#868E96">
              이용에 불편을 드려 죄송합니다.
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex w="100%" px="xl" py="sm">
        <Button
          w="100%"
          size="lg"
          h="2.5rem"
          onClick={() => window.location.reload()}
        >
          다시 시도
        </Button>
      </Flex>
    </Flex>
  );
};
