import styled from "@emotion/styled";
import { Flex } from "@mantine/core";
import { pdfjs } from "react-pdf";
import { BaseRouter } from "router";

// import { CustomerPage } from "./pages/customer";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function App() {
  return (
    <Flex w="100%" h="100%" justify="center" align="center">
      <Main>
        <BaseRouter />
      </Main>
    </Flex>
  );
}

const Main = styled.main`
  width: 100%;
  min-width: 20rem; // 320px
  max-width: 40rem; // 640px
  min-height: 100%;
`;

export default App;
