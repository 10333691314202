import { Flex, Text, Image } from "@mantine/core";
import Error400 from "assets/error400.png";

export const BadRequest = () => {
  return (
    <Flex
      direction="column"
      w="100%"
      h="100%"
      align="center"
      gap="10.125rem"
      style={{ flexShrink: 0 }}
    >
      <Flex w="100%" h="3.5rem" p="md" justify="flex-end" gap="xs">
        {/* <IconX size={24} /> */}
      </Flex>
      <Flex direction="column" h="21.6875rem" style={{ flexShrink: 0 }}>
        <Flex
          h="100%"
          justify="center"
          align="center"
          px="3.25rem"
          py="0.5625rem"
        >
          <Image src={Error400} alt="error400" />
        </Flex>
        <Flex direction="column" h="7.5rem" justify="center" align="center">
          <Flex
            h="2.75rem"
            px="md"
            py="sm"
            ta="center"
            bg="#F8F9FA"
            justify="center"
            align="center"
            style={{ flexShrink: 0, borderRadius: "0.75rem" }}
          >
            <Text fz="1.375rem" fw={700} ta="center" color="#868E96">
              비정상 접근 차단
            </Text>
          </Flex>
          <Flex
            direction="column"
            h="4.75rem"
            gap="xs"
            justify="center"
            align="center"
          >
            <Text fz="0.875rem" fw={400} ta="center" color="#868E96">
              잘못된 접근으로 요청이 차단되었습니다.
            </Text>
            <Text fz="0.875rem" fw={400} ta="center" color="#868E96">
              정상적인 경로로 다시 시도해 주시기 바랍니다.
            </Text>
          </Flex>
        </Flex>
      </Flex>
      {/* <Flex w="100%" px="xl" py="sm">
        <Button w="100%" size="lg" h="2.5rem">
          다시 시도
        </Button>
      </Flex> */}
    </Flex>
  );
};
